/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/intersection-observer@0.5.0/intersection-observer.min.js
 * - /npm/lozad@1.5.0/dist/lozad.min.js
 * - /npm/bigpicture@1.3.2/dist/BigPicture.min.js
 * - /npm/blockui@1.0.0/jquery.blockUI.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
